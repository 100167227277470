<template>
  <v-app>
    <v-container id="auth" class="fill-height">
      <v-card class="pa-5" elevation="10" color="#ffffff">
        <Login />
      </v-card>
    </v-container>
  </v-app>
</template>

<style scoped>
#auth {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--principal-azul);
  max-width: unset;
}
</style>

<script>
import Login from "./Login";

export default {
  name: "Auth",

  data: () => ({
    tabs: null,
  }),

  components: {
    Login,
  },
};
</script>